import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class PaymentMethodItem {
  constructor(payload) {
    const {
      id,
      name,
      universalName,
      minAmount,
      maxAmount,
      group,
      iconPath,
      publicForPayment,
      publicForPaymentCountry,
      blacklistForPaymentCountry,
      currency,
      processingCurrency,
      processingTime,
      recommendedForCountry,
      blockchains,
      banks,
    } = payload;
    const currencies = [];

    if (blockchains && blockchains.length && blockchains[0]?.standard) {
      currencies.push({
        currency: blockchains[0].standard,
        color: 'warning',
      });
    }

    if (processingCurrency) {
      currencies.push({
        currency: processingCurrency,
        color: 'link',
      });
    }

    Object.assign(this, {
      id,
      name,
      universalName,
      currency,
      minAmount,
      maxAmount,
      minAmountWithCurrency: new Currency({
        value: minAmount,
        symbol: currency,
        precision: Currency.getPrecision(minAmount),
      }).currency.format(),
      maxAmountWithCurrency: maxAmount ? new Currency({
        value: maxAmount,
        symbol: currency,
        precision: Currency.getPrecision(maxAmount),
      }).currency.format() : '',
      currencies,
      processingCurrency,
      group,
      iconPath,
      publicForPayment,
      publicForPaymentCountry,
      blacklistForPaymentCountry,
      processingTime,
      recommendedForCountry,
      banks,
    });
  }
}
