import {
  GETTER_IS_LOADING,
  GETTER_ACCOUNTS,
  GETTER_SELECTED_ACCOUNT,
  GETTER_PAYMENT_METHODS,
  GETTER_SELECTED_PAYMENT_METHOD,
  GETTER_RECOMMENDED_PAYMENT_METHODS,
  GETTER_IS_LOADING_CREATE_PAYMENT_REQUEST,
} from './types';

export default {
  [GETTER_IS_LOADING]: ({ isLoading }) => isLoading,
  [GETTER_IS_LOADING_CREATE_PAYMENT_REQUEST]: ({ isLoadingCreatePaymentRequest }) => isLoadingCreatePaymentRequest,
  [GETTER_ACCOUNTS]: ({ accounts }) => accounts,
  [GETTER_SELECTED_ACCOUNT]: ({ selectedAccount }) => selectedAccount,
  [GETTER_PAYMENT_METHODS]: ({ paymentMethods }) => (payload) => paymentMethods.filter(
    (paymentMethod) => {
      const { language, accountCurrency, country } = payload;
      const publicForPaymentArray = paymentMethod.publicForPayment.split(', ');
      const publicForPaymentCountryArray = paymentMethod.publicForPaymentCountry
        ? paymentMethod.publicForPaymentCountry.split(', ')
        : null;
      const blacklistForPaymentCountryArray = paymentMethod.blacklistForPaymentCountry
        ? paymentMethod.blacklistForPaymentCountry.split(', ')
        : null;
      const isPublicForCountry = publicForPaymentCountryArray
        ? publicForPaymentCountryArray.includes(country.toLowerCase())
        : true;
      const countryInBlacklist = blacklistForPaymentCountryArray
        ? blacklistForPaymentCountryArray.includes(country.toLowerCase())
        : false;

      return publicForPaymentArray.includes(language)
        && isPublicForCountry
        && !countryInBlacklist
        && paymentMethod.currency === accountCurrency;
    }),
  [GETTER_SELECTED_PAYMENT_METHOD]: ({ selectedPaymentMethod }) => selectedPaymentMethod,
  [GETTER_RECOMMENDED_PAYMENT_METHODS]: (_, getters) => (payload) => {
    const paymentMethods = getters[GETTER_PAYMENT_METHODS](payload);

    return paymentMethods.filter((paymentMethod) => {
      if (!paymentMethod.recommendedForCountry) {
        return false;
      }

      const { country } = payload;
      const recommendedForCountryArray = paymentMethod.recommendedForCountry.split(', ');

      return recommendedForCountryArray.includes(country.toLowerCase());
    });
  },
};
