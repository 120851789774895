import { Currency } from '@amarkets/atlas/esm/lib/currency';
import { DEPOSIT_ACCOUNT_TYPES, DEPOSIT_ACCOUNT_STATUSES } from '@/constants/index';

export class AccountItem {
  constructor(payload) {
    const { type, balance, currency, limits } = payload;
    const isWallet = type === DEPOSIT_ACCOUNT_TYPES.WALLET;
    const isAccount = type === DEPOSIT_ACCOUNT_TYPES.ACCOUNT;

    Object.assign(this, {
      id: isWallet ? payload.id : undefined,
      uuid: isAccount ? payload.uuid : undefined,
      login: isAccount ? payload.login : undefined,
      type,
      status: isWallet ? DEPOSIT_ACCOUNT_STATUSES.ACTIVE : payload.status,
      isWallet,
      isAccount,
      name: isWallet ? 'depositPage.deposit.wallet' : `${payload.accountType}`,
      balance: balance !== null && currency
        ? new Currency({
          value: balance,
          symbol: currency,
          precision: 2,
        }).currency.format()
        : '',
      currency,
      limits: isAccount ? limits : undefined,
    });
  }
}
