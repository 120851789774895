import { VuexFacadeBase } from '@amarkets/atlas/esm/lib/vuex-facade-base';
import {
  STORE_MODULE_GLOBAL,
  GETTER_USER_WALLET_ID,
  GETTER_USER_COUNTRY,
  GETTER_CURRENT_USER_ROLE,
  ACTION_FETCH_USER_DATA,
  GETTER_USER_DATA,
  GETTER_IS_USER_DATA_LOADING,
  GETTER_USER_EMAIL,
  GETTER_HAS_NOT_USER_PARTNER_ROLE,
  GETTER_USER_UID,
  GETTER_USER_PHONE,
  MUTATION_SET_USER_DATA,
  MUTATION_SET_USER_ROLES,
  MUTATION_SET_CURRENT_USER_ROLE,
  ACTION_FETCH_PARTNER_DATA,
  GETTER_IS_PARTNER_DATA_LOADING,
  GETTER_PARTNER_DATA,
  ACTION_FETCH_ACCOUNTS_NUMBER,
  GETTER_ACCOUNTS_DATA,
  GETTER_ACCOUNTS_DATA_LOADING,
  ACTION_FETCH_USER_PROFILE,
  GETTER_USER_PROFILE,
  GETTER_IS_USER_PROFILE_LOADING,
  ACTION_FETCH_ALL_ACCOUNTS,
  GETTER_ALL_ACCOUNTS,
  GETTER_IS_ALL_ACCOUNTS_LOADING,
  ACTION_FETCH_ACCOUNT_PROGRESS,
  GETTER_ACCOUNT_PROGRESS,
  GETTER_IS_ACCOUNT_PROGRESS_LOADING,
  ACTION_FETCH_TRADES,
  ACTION_FETCH_TRADES_TOTAL,
  GETTER_TRADES,
  GETTER_TRADES_TOTAL,
  GETTER_IS_TRADES_LOADING,
  GETTER_TRADES_PAGES_COUNT,
  ACTION_FETCH_TRADING_ACCOUNTS,
  GETTER_TRADING_ACCOUNTS,
  GETTER_TRADING_ACCOUNTS_LOADING,
  ACTION_CHANGE_TRADING_ACCOUNT_PASSWORD,
  ACTION_ARCHIVE_TRADING_ACCOUNT,
  ACTION_UN_ARCHIVE_TRADING_ACCOUNT,
  ACTION_FETCH_ARCHIVED_TRADING_ACCOUNTS,
  GETTER_ARCHIVED_TRADING_ACCOUNTS,
  ACTION_FETCH_ECN_PROMO,
  ACTION_ENABLE_ECN_PROMO,
  GETTER_ECN_PROMO,
  GETTER_IS_ENABLE_ECN_PROMO_LOADING,
  GETTER_IS_NEW_USER_PROFILE,
  ACTION_CHANGE_ACCOUNT_SETTINGS,
  MUTATION_SET_APPLICATION_TITLE,
  GETTER_APPLICATION_TITLE,
  MUTATION_SET_ACTIVE_ACCOUNT,
  GETTER_ACTIVE_ACCOUNT,
  GETTER_USER_FROM_COUNTRY_WITHOUT_REQUIRED_VERIFICATION,
  MUTATION_SET_IS_OPEN_SUCCESS_VERIFICATION_MODAL,
  GETTER_IS_SHOW_SUCCESS_VERIFICATION_MODAL,
  MUTATION_SET_WALLET_ID,
  MUTATION_SET_USER_COUNTRY,
  ACTION_ADD_MAIN_CHANNEL_SOCKET_MESSAGE,
  GETTER_MAIN_CHANNEL_SOCKET_MESSAGES,
} from '../modules/global/types';

export default class FacadeGlobal extends VuexFacadeBase {
  constructor(store) {
    super(store, STORE_MODULE_GLOBAL);
  }

  getUserWalletId = () => this.getters(GETTER_USER_WALLET_ID);
  getUserCountry = () => this.getters(GETTER_USER_COUNTRY);

  setUserWalletId = (walletId) => this.commit(MUTATION_SET_WALLET_ID, walletId);
  setUserCountry = (userCountry) => this.commit(MUTATION_SET_USER_COUNTRY, userCountry);
  setUserData = (userData) => this.commit(MUTATION_SET_USER_DATA, userData);
  setCurrentUserRole = (role) => this.commit(MUTATION_SET_CURRENT_USER_ROLE, role);
  setUserRoles = (roles) => this.commit(MUTATION_SET_USER_ROLES, roles);
  getCurrentUserRole = () => this.getters(GETTER_CURRENT_USER_ROLE);
  hasNotUserPartnerRole = () => this.getters(GETTER_HAS_NOT_USER_PARTNER_ROLE);

  fetchUserData = ({ userId }) => this.dispatch(ACTION_FETCH_USER_DATA, { userId });
  getUserData = () => this.getters(GETTER_USER_DATA);
  getUserEmail = () => this.getters(GETTER_USER_EMAIL);
  getUserUid = () => this.getters(GETTER_USER_UID);
  getUserPhone = () => this.getters(GETTER_USER_PHONE);
  isUserDataLoading = () => this.getters(GETTER_IS_USER_DATA_LOADING);

  fetchNumberOfAccounts = () => this.dispatch(ACTION_FETCH_ACCOUNTS_NUMBER);
  isAccountsDataLoading = () => this.getters(GETTER_ACCOUNTS_DATA_LOADING);
  getAccountsData = () => this.getters(GETTER_ACCOUNTS_DATA);

  fetchPartnerProfile = () => this.dispatch(ACTION_FETCH_PARTNER_DATA);
  isPartnerDataLoading = () => this.getters(GETTER_IS_PARTNER_DATA_LOADING);
  getPartnerData = () => this.getters(GETTER_PARTNER_DATA);

  fetchUserProfile = () => this.dispatch(ACTION_FETCH_USER_PROFILE);
  userProfile = () => this.getters(GETTER_USER_PROFILE);
  userFromCountryWithoutRequiredVerification = () => this.getters(
    GETTER_USER_FROM_COUNTRY_WITHOUT_REQUIRED_VERIFICATION,
  );
  isNewUserProfile = () => this.getters(GETTER_IS_NEW_USER_PROFILE);
  isUserProfileLoading = () => this.getters(GETTER_IS_USER_PROFILE_LOADING);

  fetchTradingAccounts = (onSuccess) => this.dispatch(ACTION_FETCH_TRADING_ACCOUNTS, { onSuccess });
  archiveTradingAccount = (payload) => this.dispatch(ACTION_ARCHIVE_TRADING_ACCOUNT, payload);
  unArchiveTradingAccount = (payload) => this.dispatch(ACTION_UN_ARCHIVE_TRADING_ACCOUNT, payload);
  getTradingAccounts = () => this.getters(GETTER_TRADING_ACCOUNTS);
  isTradingAccountsLoading = () => this.getters(GETTER_TRADING_ACCOUNTS_LOADING);

  fetchAllAccounts = (uuid) => this.dispatch(ACTION_FETCH_ALL_ACCOUNTS, uuid);
  getAllAccounts = () => this.getters(GETTER_ALL_ACCOUNTS);
  isAllAccountsLoading = () => this.getters(GETTER_IS_ALL_ACCOUNTS_LOADING);
  fetchAccountProgress = (accountId) => this.dispatch(ACTION_FETCH_ACCOUNT_PROGRESS, accountId);
  getAccountProgress = () => this.getters(GETTER_ACCOUNT_PROGRESS);
  isAccountProgressLoading = () => this.getters(GETTER_IS_ACCOUNT_PROGRESS_LOADING);

  fetchTrades = (payload) => this.dispatch(ACTION_FETCH_TRADES, payload);
  fetchTradesTotal = (payload) => this.dispatch(ACTION_FETCH_TRADES_TOTAL, payload);
  getTrades = () => this.getters(GETTER_TRADES);
  getTradesTotal = () => this.getters(GETTER_TRADES_TOTAL);
  isTradesLoading = () => this.getters(GETTER_IS_TRADES_LOADING);
  getTradesPagesCount = () => this.getters(GETTER_TRADES_PAGES_COUNT);

  changeAccountPassword = (payload) => this.dispatch(ACTION_CHANGE_TRADING_ACCOUNT_PASSWORD, payload);
  fetchArchivedTradingAccounts = () => this.dispatch(ACTION_FETCH_ARCHIVED_TRADING_ACCOUNTS);
  getArchivedTradingAccounts = () => this.getters(GETTER_ARCHIVED_TRADING_ACCOUNTS);

  fetchEcnPromo = () => this.dispatch(ACTION_FETCH_ECN_PROMO);
  getEcnPromo = () => this.getters(GETTER_ECN_PROMO);
  enableEcnPromo = (payload) => this.dispatch(ACTION_ENABLE_ECN_PROMO, payload);
  isEnableEcnPromoLoading = () => this.getters(GETTER_IS_ENABLE_ECN_PROMO_LOADING);

  changeAccountSettings = (payload) => this.dispatch(ACTION_CHANGE_ACCOUNT_SETTINGS, payload);

  setApplicationTitle = (title) => this.commit(MUTATION_SET_APPLICATION_TITLE, title);
  getApplicationTitle = () => this.getters(GETTER_APPLICATION_TITLE);

  setActiveAccount = (login) => this.commit(MUTATION_SET_ACTIVE_ACCOUNT, login);
  getActiveAccount = () => this.getters(GETTER_ACTIVE_ACCOUNT);
  setShowSuccessVerificationModal = (status) => this.commit(MUTATION_SET_IS_OPEN_SUCCESS_VERIFICATION_MODAL, status);
  isShowSuccessVerificationModal = () => this.getters(GETTER_IS_SHOW_SUCCESS_VERIFICATION_MODAL);

  addNewMessageToMainSocketChannel = (message) => this.dispatch(ACTION_ADD_MAIN_CHANNEL_SOCKET_MESSAGE, message);

  mainChannelSocketMessages = () => this.getters(GETTER_MAIN_CHANNEL_SOCKET_MESSAGES);
}
