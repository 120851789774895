import { Action } from '@amarkets/atlas/esm/lib/action';
import { PaymentMethodsMapper } from '../mappers/payment-methods-mapper';
import { PaymentMethodItem } from '../entities/payment-method-item';
import { MUTATION_SET_PAYMENT_METHODS } from '../types';

export class FetchPaymentMethods extends Action {
  _api;
  _handleError;

  constructor({ depositApi, errorHandler }) {
    super();
    this._api = depositApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    try {
      const paymentMethodsMapper = new PaymentMethodsMapper((opts) => new PaymentMethodItem(opts));
      const paymentMethods = await this._api.getPaymentMethods((data) => paymentMethodsMapper.mapAccounts(data));

      commit(MUTATION_SET_PAYMENT_METHODS, paymentMethods);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchAccounts');
    }
  }
}
